@import '@/styles/common';

.headingBody {
  display: block;
  color: $black;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;

  a {
    color: $blue2;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.form {
  margin-bottom: 24px;

  @include media-breakpoint-down(md) {
    margin-bottom: 16px;
  }
}

.filter {
  display: none;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 18px;
  }
}

.aside {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 260px;

  @include media-breakpoint-down(lg) {
    width: 230px;
  }
}

.sns {
  position: absolute;
  bottom: -40px;
}

.heading {
  margin-bottom: 4px;
  font-size: 20px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.button {
  width: 210px;
  margin: 0 auto;
}

.buttonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.buttonLabel {
  color: $black1;
  font-weight: 500;
}
